import React, { useEffect, useState } from "react";

const QuizMasterDesktopQuestions = ({
  headRef,
  language,
  handleStartQuiz,
  questionCounter,
  AnswerClick,
  selectedAnswer,
  correctAnswerIndex,
  isAnswerCorrect,
  currentQuestion,
  questionAmount,
  loadingQuiz,
}) => {
  const [firstQuestion, setFirstQuestion] = useState(true);
  const [explanation, setExplanation] = useState("");

  function handleExplanation(lang) {
    const explanations = {
      nl: "Ik ga je een vraag stellen en jij moet kiezen uit vier antwoorden, waarvan jij denkt dat het juiste antwoord is. Snap je wat ik bedoel?",
      en: "I will ask you a question and you have to choose from four answers, which you think is the correct answer. Do you understand?",
    };
    setExplanation(explanations[lang]);
  }

  useEffect(() => {
    if (firstQuestion) {
      handleExplanation(language);
      if (headRef.current) {
        headRef.current.speakText(explanation);
      }
    }
  }, [firstQuestion, language, explanation, headRef]);

  function handleAnswer(answer) {
    switch (answer) {
      case "yes":
        setFirstQuestion(false);
        handleStartQuiz();
        break;
      case "no":
        headRef.current.speakText(
          language === "nl"
            ? "Nee? Oke, laten we het dan opnieuw proberen!"
            : "No? Okay, let's try again!"
        );
        handleExplanation(language);
        break;
      case "maybe":
        headRef.current.speakText(
          language === "nl"
            ? "Misschien? Oke, laten we het dan proberen, en kijken we weer of je het snapt!"
            : "Maybe? Okay, let's try it and see if you understand!"
        );
        setFirstQuestion(false);
        handleStartQuiz();
        break;
      case "explain":
        headRef.current.speakText(
          language === "nl"
            ? "Ok! Ik leg het opnieuw uit!"
            : "Okay! I'll explain it again!"
        );
        handleExplanation(language);
        break;
    }
  }

  function getButtonClass(index) {
    if (selectedAnswer === null) return "";
    if (index === correctAnswerIndex) return "correct";
    if (index === selectedAnswer) return isAnswerCorrect ? "correct" : "incorrect";
    return "";
  }

  return (
    <div className="QuizMaster-desktop-questions">
      <div className="QuizMaster-desktop-questions-content">
        {firstQuestion ? (
          <>
            <div className="QuizMaster-desktop-questions-top-section">
              <h2 className="QuizMaster-desktop-questions-title">
                {language === "nl" ? "Uitleg" : "Explanation"}
              </h2>
              <p className="QuizMaster-desktop-questions-explanation">
                {explanation}
              </p>
            </div>
            <div className="QuizMaster-desktop-questions-bottom-section">
              <div className="QuizMaster-desktop-questions-buttons">
                <div className="top-row">
                <button onClick={() => handleAnswer("yes")}>
                  {language === "nl" ? "Ja!" : "Yes!"}
                </button>
                <button onClick={() => handleAnswer("maybe")}>
                  {language === "nl" ? "Misschien..?" : "Maybe..?"}
                </button>
              </div>
              <div className="bottom-row">
                <button onClick={() => handleAnswer("no")}>
                  {language === "nl" ? "Nee..." : "No..."}
                </button>
                <button onClick={() => handleAnswer("explain")}>
                  {language === "nl" ? "Laat me maar eerst kijken" : "Let me see first"}
                </button>
              </div>
            </div>
            </div>
          </>
        ) : (
          <>
            {loadingQuiz ? (
              <div className="QuizMaster-desktop-questions-title">
                <h3>{language === "nl" ? "Quiz is aan het laden..." : "Quiz is loading..."}</h3>
              </div>
            ) : (
              <>
                <div className="QuizMaster-desktop-questions-title">
                  <h3>
                    {questionCounter + 1}/{questionAmount}
                  </h3>
                </div>
                <div className="QuizMaster-desktop-questions-explanation">
                  <h2>{currentQuestion.question}</h2>
                </div>
                <div className="QuizMaster-desktop-questions-bottom-section">
                <div className="QuizMaster-desktop-questions-buttons">
                  <div className="top-row">
                    {currentQuestion.answerOptions.slice(0, 2).map((option, index) => (
                      <button
                        key={index}
                        onClick={() => AnswerClick(option, index)}
                        className={getButtonClass(index)}
                        disabled={selectedAnswer !== null}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                  <div className="bottom-row">
                    {currentQuestion.answerOptions.slice(2, 4).map((option, index) => (
                      <button
                        key={index + 2}
                        onClick={() => AnswerClick(option, index + 2)}
                        className={getButtonClass(index + 2)}
                        disabled={selectedAnswer !== null}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuizMasterDesktopQuestions;
