import './Quizmaster.css';
import React, { useEffect, useState, useRef } from 'react';
import { TalkingHead } from "../utils/Talkinghead";
import axios from 'axios';
import useWindowSize from '../utils/useWindowSize';
import QuizMasterMobile from './Mobile/QuizMasterMobile';
import { QuizMasterDesktop } from './Desktop/QuizMasterDesktop';
let loaded = false;
let isAvatarThinking = false; // Between ending the recording and the text being spoken

let showPopup = true; // Consent for video and microphone

function Quizmaster() {
  const videoRef = useRef();
  const [pauseButtonText, setPauseButtonText] = useState('Pause');
  const [consentVideoMicrophone, setConsentVideoMicrophone] = useState(false);
  const [lockStartButton, setLockStartButton] = useState(true);
  const windowSize = useWindowSize();
  const [subject, setSubject] = useState('');
  const [questions, setQuestions] = useState([]);
  const [questionAmount, setQuestionAmount] = useState(5);
  const [questionCounter, setQuestionCounter] = useState(0);
  const [isQuizActive, setIsQuizActive] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [showQuestion, setShowQuestion] = useState(false);

  const [selectedAnswer, setSelectedAnswer] = useState(null); // Track selected answer
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null); // Track correct answer index
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null); // Track if answer is correct
  const [isMobile, setIsMobile] = useState(false);
  const [language, setLanguage] = useState("en");
  const [loadingQuiz, setLoadingQuiz] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
  // const [quizOnderwerp, setQuizOnderwerp] = useState("");
  const avatarRef = useRef(null);
  const loadingRef = useRef(null);
  const headRef = useRef(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [moveToNextQuestion, setMoveToNextQuestion] = useState(false);
  const [finalQuestionAmount, setFinalQuestionAmount] = useState(null);

  useEffect(() => {
    setIsMobile(windowSize.width < 768);
  }, [windowSize]);

  useEffect(() => {
    if (!loaded && !isQuizActive) {
      setTimeout(() => {
        setLockStartButton(false);
      }, 3000);
    } else if (isQuizActive && questions.length > 0) {
      // StartQuestion(); Removed to handle speech in useEffect
    }
  }, [isQuizActive, currentQuestion, questions, questionCounter]);

  useEffect(() => {
    if (moveToNextQuestion) {
      const timer = setTimeout(() => {
        StartNextQuestion();
        setMoveToNextQuestion(false);
      }, 500); // Adjust this delay as needed
      return () => clearTimeout(timer);
    }
  }, [moveToNextQuestion]);

  useEffect(() => {
    if (questions.length > 0 && finalQuestionAmount === null) {
      setFinalQuestionAmount(questions.length);
    }
  }, [questions]);

  // New useEffect to handle speaking the current question
  useEffect(() => {
    if (isQuizActive && currentQuestion && selectedAnswer === null) {
      console.log("Speaking question: ", currentQuestion.question);
      headRef.current.speakText(`${currentQuestion.question}`);
      setShowQuestion(true);
    }
  }, [currentQuestion, isQuizActive, selectedAnswer]);

  const handleLanguageSelect = (languageCode) => {
    console.log(languageCode);
    const selectedLanguage = languageCode === "US" ? "en" : "nl";
    setLanguage(selectedLanguage);

    if (headRef.current) {
      if (selectedLanguage === "en") {
        headRef.current.ttsLang = "en-GB";
        headRef.current.ttsVoice = "en-GB-Standard-B";
        headRef.current.lipsyncLang = "en";
      } else if (selectedLanguage === "nl") {
        headRef.current.ttsLang = "nl-NL";
        headRef.current.ttsVoice = "nl-NL-Standard-C";
        headRef.current.lipsyncLang = "nl";
      }
    }
  };

  const handleInputChange = (event) => {
    setSubject(event.target.value);
  };

  // Loads the avatar on the screen.
  async function loadAvatar() {
    if (loaded || !avatarRef.current) { 
      console.log("Avatar not loaded");
      return;
    }
    loaded = true;
    console.log("Avatar loaded");

    // Instantiate the class
    // NOTE: Never put your API key in a client-side code unless you know
    //       that you are the only one to have access to that code!
    const nodeAvatar = avatarRef.current;
    headRef.current = new TalkingHead(nodeAvatar, {
      ttsEndpoint: "https://eu-texttospeech.googleapis.com/v1beta1/text:synthesize",
      ttsApikey: process.env.REACT_APP_GOOGLE_TTS_API_KEY, 
      lipsyncModules: ["en", "nl"], // Load both languages
      cameraView: "upper"
    });

    // Load and show the avatar
    const nodeLoading = loadingRef.current;
    try {
      nodeLoading.textContent = "Loading...";
      let config = {};

      if (language === "en") {
        config = {
          url: 'https://models.readyplayer.me/66defc1e253e9210e373b22f.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
          body: "M",
          ttsVoice: "en-GB-Standard-B",
          avatarMood: "neutral",
          ttsLang: "en-GB",
          lipsyncLang: "en",
        };
      } else if (language === "nl") {
        config = {
          url: 'https://models.readyplayer.me/66defc1e253e9210e373b22f.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
          body: "M",
          ttsVoice: "nl-NL-Standard-C",
          avatarMood: "neutral",
          ttsLang: "nl-NL",
          lipsyncLang: "nl",
        };
      }
      await headRef.current.showAvatar(config, (ev) => {
        if (ev.lengthComputable) {
          let val = Math.min(1000, Math.round(ev.loaded / ev.total * 100));
          nodeLoading.textContent = "Loading " + val + "%";
        }
      });
      nodeLoading.style.display = 'none';
    } catch (error) {
      nodeLoading.textContent = error.toString();
    }     
  }

  function pauseButton(){
    if (isAvatarThinking){
      headRef.current.start();
      setPauseButtonText('Pause');
    } else {
      headRef.current.stop();
      setPauseButtonText('Resume');
    }
    isAvatarThinking = !isAvatarThinking;
  }

  // Close the introduction modal
  function closeModal(){
    setShowPopup(false);
    headRef.current.speakText(`Let me tell you how this quiz will work while it loads. I will ask you a series of questions. You will have to answer them by clicking on the correct answer. Are you ready? We will begin soon.`);
    GenerateQuiz();
  }

  async function GenerateQuiz(){
    setLoadingQuiz(true);
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHQuiz`, {subject: subject, amount: questionAmount});
    console.log("Response: ", response.data);
    const newQuestions = response.data.questions;
    setQuestions(newQuestions);
    setQuestionCounter(0);
    setCurrentQuestion(newQuestions[0]); // Set the first question
    setQuestionAmount(newQuestions.length);
    setLoadingQuiz(false);
  }

  function StartQuiz(){
    setIsQuizActive(true);
  }

  const AnswerClick = (item, index) => {
    // Prevent multiple answers
    if (selectedAnswer !== null) return;

    // Stop the current speech and animation
    headRef.current.stopSpeaking();
    
    if (item === questions[questionCounter].answerCorrect) {
      headRef.current.speakText(`Correct! ${questions[questionCounter].answerCorrectDescription}`);
      setIsAnswerCorrect(true);
      setTotalCorrectAnswers(totalCorrectAnswers + 1);
    } else {
      headRef.current.speakText(`Incorrect! ${questions[questionCounter].answerCorrectDescription}`);
      setIsAnswerCorrect(false);
    }

    // Update selected answer and correct answer index
    setSelectedAnswer(index);
    setCorrectAnswerIndex(questions[questionCounter].answerOptions.indexOf(questions[questionCounter].answerCorrect));

    if (questionCounter < questionAmount - 1) {
      // Instead of calling StartNextQuestion immediately, set a flag to move to the next question
      setMoveToNextQuestion(true);
    } else {
      EndQuiz();
    }
  };

  function ShowPopup(){
    if (!headRef.current.isSpeaking){
      setShowPopup(true);
    } else {
      setTimeout(() => {
        ShowPopup();
      }, 1000);
    }
  }

  function StartNextQuestion(){
    console.log("Question amount: ", questionAmount);
    if (!headRef.current.isSpeaking){
      setSelectedAnswer(null);
      setIsAnswerCorrect(null);
      setCorrectAnswerIndex(null); // Reset correctAnswerIndex
      setQuestionCounter(prevCounter => prevCounter + 1);
      setCurrentQuestion(questions[questionCounter + 1]);
      // Speech is handled by useEffect
    } else {
      setTimeout(() => {
        StartNextQuestion();
      }, 500);
    }
  }

  function EndQuiz(){
    if (!headRef.current.isSpeaking){
      headRef.current.speakText(`That was the last question. Thank you for playing!`);
      
      setTimeout(() => {
        setSelectedAnswer(null);
        setIsAnswerCorrect(null);
        setCorrectAnswerIndex(null);
        setQuestions([]);
        setQuestionAmount(1);
        setQuestionCounter(0);
        setShowQuestion(false);
        setIsQuizActive(false);
        setShowResults(true);
      }, 5000);
    } else {
      setTimeout(() => {
        EndQuiz();
      }, 1000);
    }
  }

  const incrementValue = () => {
    setQuestionAmount((prev) => (prev < 10 ? prev + 1 : 10));
  };

  const decrementValue = () => {
    setQuestionAmount((prev) => (prev > 1 ? prev - 1 : 1));
  };

  return (
    <div>
      {isMobile ? (
        <QuizMasterMobile
          avatarRef={avatarRef}
          loadingRef={loadingRef}
          loadAvatar={loadAvatar}
          subject={subject}
          setSubject={setSubject}
          questionAmount={finalQuestionAmount || questionAmount}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          questions={questions}
          questionCounter={questionCounter}
          AnswerClick={AnswerClick}
          selectedAnswer={selectedAnswer}
          correctAnswerIndex={correctAnswerIndex}
          isAnswerCorrect={isAnswerCorrect}
          headRef={headRef}
          handleLanguageSelect={handleLanguageSelect}
          handleInputChange={handleInputChange}
          language={language}
          loaded={loaded}
          GenerateQuiz={GenerateQuiz}
          StartQuiz={StartQuiz}
          currentQuestion={currentQuestion}
          loadingQuiz={loadingQuiz}
          showResults={showResults}
          totalCorrectAnswers={totalCorrectAnswers}
        />
      ) : (
        <QuizMasterDesktop
          avatarRef={avatarRef}
          loadingRef={loadingRef}
          headRef={headRef}
          loadAvatar={loadAvatar}
          subject={subject}
          setSubject={setSubject}
          questionAmount={finalQuestionAmount || questionAmount}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          questions={questions}
          language={language}
          handleLanguageSelect={handleLanguageSelect}
          handleInputChange={handleInputChange}
          loaded={loaded}
          GenerateQuiz={GenerateQuiz}
          StartQuiz={StartQuiz}
          currentQuestion={currentQuestion}
          loadingQuiz={loadingQuiz}
          showResults={showResults}
          totalCorrectAnswers={totalCorrectAnswers}
          AnswerClick={AnswerClick}
          selectedAnswer={selectedAnswer}
          correctAnswerIndex={correctAnswerIndex}
          isAnswerCorrect={isAnswerCorrect}
          questionCounter={questionCounter}
         />
      )}
    </div>
  );
}

export default Quizmaster;