import React, { useState, useEffect, useRef } from 'react';
import { QuizMobileIntroModal } from './QuizMobileIntroModal';
import QuizMasterMobileQuestions from './QuizMasterMobileQuestions';
import QuizMasterMobileResults from './QuizMasterMobileResults';

const QuizMasterMobile = ({
  avatarRef,
  loadingRef,
  loadAvatar,
  showPopup,
  setShowPopup,
  questions,
  questionCounter,
  AnswerClick,
  selectedAnswer,
  correctAnswerIndex,
  isAnswerCorrect,
  headRef,
  handleLanguageSelect,
  handleInputChange,
  language,
  GenerateQuiz,
  StartQuiz,
  currentQuestion,
  questionAmount,
  loadingQuiz,
  showResults,
  totalCorrectAnswers
}) => {
  const [firstQuestion, setFirstQuestion] = useState(true);
  const questionAmountRef = useRef(questionAmount);

  useEffect(() => {
    // Update the ref when questionAmount changes
    questionAmountRef.current = questionAmount;
  }, [questionAmount, showResults, totalCorrectAnswers]);

  function handleCloseModal() {
    setShowPopup(false);
    GenerateQuiz();
  }

  function handleStartQuiz() {
    StartQuiz();
  }
    
  return (
    <div className={`Page-QuizMaster`}>
      {showPopup ? (
        <QuizMobileIntroModal
          language={language}
          handleLanguageSelect={handleLanguageSelect}
          handleInputChange={handleInputChange}
          handleCloseModal={handleCloseModal}
        />
      ) : showResults ? (
        <QuizMasterMobileResults
          totalCorrectAnswers={totalCorrectAnswers}
          questionAmount={questionAmountRef.current}
          language={language}
        />
      ) : (
        <QuizMasterMobileQuestions
          headRef={headRef}
          firstQuestion={firstQuestion}
          setFirstQuestion={setFirstQuestion}
          language={language}
          avatarRef={avatarRef}
          loadingRef={loadingRef}
          loadAvatar={loadAvatar}
          handleStartQuiz={handleStartQuiz}
          currentQuestion={currentQuestion}
          AnswerClick={AnswerClick}
          selectedAnswer={selectedAnswer}
          correctAnswerIndex={correctAnswerIndex}
          isAnswerCorrect={isAnswerCorrect}
          questionAmount={questionAmount}
          loadingQuiz={loadingQuiz}
          questionCounter={questionCounter}
          StartQuiz={StartQuiz}
        />
      )}
    </div>
  );
};

export default QuizMasterMobile;
