import "../KinderStories.css";
import "./KinderStoriesDesktop.css";
import mach8Logo from "../../assets/images/mach8-logo.svg";
import React, { useEffect } from "react";

export default function KinderStoriesDesktop({
  pauseButton,
  pauseButtonText,
  showPopup,
  closeModal,
  recordingIndicator,
  loadAvatar,
  language,
  avatarRef,
  loadingRef,
  lockStartButton,
}) {
    useEffect(() => {
        console.log("Avatar ref:", avatarRef);
        if (avatarRef && avatarRef.current) {
          console.log("Loading avatar in component");
          loadAvatar();
        }
      }, [avatarRef, avatarRef]);
  return (
    <div className="KinderStories-Desktop">
      <div className="mach8-logo">
        <div className="mach8-logo-vector">
          <img src={mach8Logo} alt="Mach8 Logo" />
        </div>
      </div>
      {recordingIndicator ? (
        <div className="kinder-stories-recording-indicator">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            width="40"
            height="40"
          >
            <path d="M192 0C139 0 96 43 96 96l0 160c0 53 43 96 96 96s96-43 96-96l0-160c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 89.1 66.2 162.7 152 174.4l0 33.6-48 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l72 0 72 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0 0-33.6c85.8-11.7 152-85.3 152-174.4l0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 70.7-57.3 128-128 128s-128-57.3-128-128l0-40z" />
          </svg>
          Ik ben aan het luisteren
          <div className="dots">
            <div className="dot-1">.</div>
            <div className="dot-2">.</div>
            <div className="dot-3">.</div>
          </div>
        </div>
      ) : null}
      {!showPopup && (
        <button className="kinder-stories-pause-button" onClick={pauseButton}>
          {pauseButtonText}
        </button>
      )}
      
        <div className="kinder-stories-intro-modal">
        {showPopup && (
          <div className="kinder-stories-intro-modal-top">
            <div className="kinder-stories-intro-modal-header">
              <h3 className="kinder-stories-intro-modal-top-text">
                Fun starts with
              </h3>
              <h3 className="kinder-stories-intro-modal-top-name">
                <div className="letter kinder-stories-intro-modal-letter-M">
                  M
                </div>
                <div className="letter kinder-stories-intro-modal-letter-A">
                  A
                </div>
                <div className="letter kinder-stories-intro-modal-letter-X">
                  X
                </div>
              </h3>
            </div>
          </div>
          )}
          <div className="kinder-stories-intro-modal-bottom">
            {showPopup && <div className="kinder-stories-chatbubble-left">Samen kunnen we spelletjes doen!</div>}
            <div className={showPopup ? "kinder-stories-avatar-container-popup" : "kinder-stories-avatar-container"}>
                <div
                  className={showPopup ? "avatar kinder-stories-avatar-popup" : "avatar kinder-stories-avatar"}
                  ref={avatarRef}
                ></div>
            </div>
            {showPopup && <div className="kinder-stories-chatbubble-right">Ik kan je de leukste verhaaltjes voorlezen!</div>}
          </div>
          <div ref={loadingRef} id="loading" className="text-black"></div>
          {showPopup && (
            <div className="kinder-stories-intro-modal-bottom-button-container">
              <button className="kinder-stories-intro-modal-bottom-button" onClick={closeModal}>Begin!</button>
            </div>
          )}
        </div>
      
    </div>
  );
}
