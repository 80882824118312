import React, { useEffect } from "react";
import VHIntroModal from "../../Components/VHIntroModal";
import mach8Logo from "../../assets/images/mach8-logo.svg";
import QuizMasterDesktopQuestions from "./QuizMasterDesktopQuestions";
import QuizMasterDesktopResults from "./QuizMasterDesktopResults";

export const QuizMasterDesktop = ({
  showPopup,
  setShowPopup,
  questions,
  questionCounter,
  AnswerClick,
  selectedAnswer,
  correctAnswerIndex,
  isAnswerCorrect,
  avatarRef,
  loadingRef,
  loadAvatar,
  subject,
  setSubject,
  questionAmount,
  handleLanguageSelect,
  handleInputChange,
  language,
  loaded,
  GenerateQuiz,
  StartQuiz,
  currentQuestion,
  loadingQuiz,
  showResults,
  totalCorrectAnswers,
  headRef,
}) => {
  useEffect(() => {
    if (avatarRef && avatarRef.current) {
      loadAvatar();
    }
  }, [avatarRef, loadAvatar]);

  function handleCloseModal() {
    setShowPopup(false);
    GenerateQuiz();
  }

  function handleStartQuiz() {
    StartQuiz();
  }

  return (
    <div className="QuizMaster-desktop">
      <div className="mach8-logo">
        <div className="mach8-logo-vector">
          <img src={mach8Logo} alt="Mach8 Logo" />
        </div>
      </div>
      {showPopup ? (
        <VHIntroModal
          isMobileDevice={false}
          language={language}
          userDescription={subject}
          isComplimenting={false}
          handleLanguageSelect={handleLanguageSelect}
          selectLanguage={handleLanguageSelect}
          handleInputChange={handleInputChange}
          handleModalButton={handleCloseModal}
          buttonText="Start Quiz!"
          buttonStyle="QuizMaster-desktop-intro-button"
          titleText={
            <>
              <p className="QuizMaster-desktop-intro-text">
                Test your knowledge with
              </p>
              {/* <br /> */}
              <span
                className={`QuizMaster-desktop-intro-text-bold mr-4`}
                style={{ color: "white" }}
              >
                QuizMaster
              </span>
              <span
                className={`QuizMaster-desktop-intro-text-bold`}
                style={{ color: "#3C0E1E" }}
              >
                Max!
              </span>
            </>
          }
          infoText={
            language === "nl"
              ? "Maak een quiz over elk onderwerp en laat Quizmaster Max test je kennis!"
              : "Generate a quiz about any subject and let Quizmaster Max test your knowledge!"
          }
          contextText={`${
            language === "nl" ? "Quiz onderwerp:" : "Quiz subject:"
          }`}
          pageName="QuizMaster"
        />
      ) : showResults ? (
        <QuizMasterDesktopResults
          totalCorrectAnswers={totalCorrectAnswers}
          questionAmount={questionAmount}
          language={language}
        />
      ) : (
        <QuizMasterDesktopQuestions
          headRef={headRef}
          language={language}
          handleStartQuiz={handleStartQuiz}
          questionCounter={questionCounter}
          AnswerClick={AnswerClick}
          selectedAnswer={selectedAnswer}
          correctAnswerIndex={correctAnswerIndex}
          isAnswerCorrect={isAnswerCorrect}
          currentQuestion={currentQuestion}
          questionAmount={questionAmount}
          loadingQuiz={loadingQuiz}
        />
      )}
      <div
        className={`${
          showPopup || showResults
            ? "QuizMaster-avatar-container justify-self-end"
            : "QuizMaster-avatar-container-questions"
        }`}
      >
        {!showPopup && !showResults && <div className="QuizMaster-avatar-container-circle" />}
        <div
          className={`${
            showPopup || showResults
              ? "avatar QuizMaster"
              : "avatar QuizMaster-questions"
          }`}
          ref={avatarRef}
        ></div>
        <div ref={loadingRef} id="loading" className="text-black"></div>
      </div>
    </div>
  );
};
