import React from 'react';
import ReactMarkdown from 'react-markdown';

function VHFinishedPage({
  latestImage,
  quotes,
  email,
  handleEmailChange,
  handleMail,
  buttonStyle,
  buttonText,
  pageName, // Added pageName as a prop
}) {
  return (
    <div className={`${pageName}-Finished-container`}>
      <img src={latestImage} alt="Your Picture" className={`${pageName}-picture-finished`} />
      <ReactMarkdown className={`${pageName}-roast-text`}>{quotes}</ReactMarkdown>
      <div className={`${pageName}-email-section`}>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          className={`${pageName}-input-field`}
          placeholder="Enter your email..."
        />
        <button className={buttonStyle} onClick={handleMail}>
          {buttonText}
        </button>
      </div>
    </div>
  );
}

export default VHFinishedPage;