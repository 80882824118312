import "../Desktop/OuderenMaatjeDesktop.css";
export default function ThoughtBubble({
  text,
  backgroundColor = "bg-[#fdf4e3]",
  textColor = "text-black",
}) {
  return (
    <div className="relative w-[90vw] sm:w-full sm:max-w-xl mx-auto mt-20">
      <div
        className={`${backgroundColor} ${textColor} p-6 rounded-3xl text-2xl mb-4 shadow-lg thought-bubble-text flex flex-row items-center gap-4`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          width="40"
          height="40"
        >
          <path d="M192 0C139 0 96 43 96 96l0 160c0 53 43 96 96 96s96-43 96-96l0-160c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 89.1 66.2 162.7 152 174.4l0 33.6-48 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l72 0 72 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0 0-33.6c85.8-11.7 152-85.3 152-174.4l0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 70.7-57.3 128-128 128s-128-57.3-128-128l0-40z" />
        </svg>
        {text}
        <div className="dots">
          <div className="dot-1">.</div>
          <div className="dot-2">.</div>
          <div className="dot-3">.</div>
        </div>
      </div>
      <div
        className={`absolute -bottom-0 left-4 w-10 h-10 rounded-full ${backgroundColor}`}
      ></div>
      <div
        className={`absolute -bottom-6 left-12 w-7 h-7 rounded-full ${backgroundColor}`}
      ></div>
      <div
        className={`absolute -bottom-10 left-20 w-5 h-5 rounded-full ${backgroundColor}`}
      ></div>
    </div>
  );
}
