export const base64ToArrayBuffer = (base64) => {
    // Decode Base64 into a binary string
    const binaryString = window.atob(base64);
    
    // Create a new Uint8Array to hold the decoded binary data
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    
    // Map each character in the binary string to its charCode, and store it in the Uint8Array
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Return the ArrayBuffer representation of the Uint8Array
    return bytes.buffer;
};