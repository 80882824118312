import './BrightenUP.css';
import React, { useEffect, useState, useRef } from 'react';
import { TalkingHead } from "../utils/Talkinghead";
import axios from 'axios';


let head = null;
let loaded=false;
let isWaitingForDescriptionResult=false;
let isAvatarThinking=false; // Between ending the recording and the text being spoken

function App() {
  const videoRef = useRef();
  const [pauseButtonText, setPauseButtonText] = useState('Pause');
  const [consentVideoMicrophone, setConsentVideoMicrophone] = useState(false);
  const [lockStartButton, setLockStartButton] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [countdown, setCountdown] = useState(3);
  const [showCountdown, setShowCountdown] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [latestImage, setLatestImage] = useState(null);
  const [language, setLanguage] = useState('en');
  const [userDescription, setUserDescription] = useState("");
  const [userName, setUserName] = useState("");
  const [isMobileDevice, setIsMobileDevice] = useState(false);


  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        setLockStartButton(false);
      }, 3000);
    }
    if (consentVideoMicrophone) {
      startVideo();    
      loadAvatar();
      setShowPopup(false);
    }
    
    isMobile();
    setConsentVideoMicrophone(false);
  }, [consentVideoMicrophone]);


  // Starts the videofeed.
  const startVideo = () => {

    navigator.mediaDevices.getUserMedia({ video: {} })
      .then(stream => {
        videoRef.current.srcObject = stream;
        videoRef.muted = true;
      })
      .catch(err => console.error('Error accessing webcam: ', err));
  };

  // Loads the avatar on the screen.
  const loadAvatar=async function loadAvatar() {
      loaded=true;
      // Instantiate the class
      // NOTE: Never put your API key in a client-side code unless you know
      //       that you are the only one to have access to that code!
      const nodeAvatar = document.getElementById('avatar');
      head = new TalkingHead(nodeAvatar, {
        ttsEndpoint: "https://eu-texttospeech.googleapis.com/v1beta1/text:synthesize",
        ttsApikey: process.env.REACT_APP_GOOGLE_TTS_API_KEY, 
        lipsyncModules: ["en"],
        cameraView: "upper"
      });

      // Load and show the avatar
      const nodeLoading = document.getElementById('loading');
      try {
        nodeLoading.textContent = "Loading...";
        let config = {}
        if (language === 'en') {
          config = {
            url: 'https://models.readyplayer.me/66f15a4704ad957f4a82bf58.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
            body: 'M',
            ttsVoice: "en-GB-Standard-B",
            avatarMood: 'neutral',
            ttsLang: "en-GB",
            lipsyncLang: 'en'
          }
        } else if (language === 'nl') {
          config = {
            url: 'https://models.readyplayer.me/66f15a4704ad957f4a82bf58.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
            body: 'M',
            ttsVoice: "nl-NL-Standard-C",
            avatarMood: 'neutral',
            ttsLang: "nl-NL",
            lipsyncLang: 'nl'
          }
        }
        await head.showAvatar(config, (ev) => {
          if (ev.lengthComputable) {
            let val = Math.min(1000, Math.round(ev.loaded / ev.total * 100));
            nodeLoading.textContent = "Loading " + val + "%";
          }
   
        });
        nodeLoading.style.display = 'none';
      } catch (error) {
        nodeLoading.textContent = error.toString();
      }     
  }

// Describes the image of the user everytime the recording is started. This way a description is ready for the conversation.
const DescribeImage = async () => {
  if (isWaitingForDescriptionResult) {
    return;
  }

  isWaitingForDescriptionResult=true;
  const video=videoRef.current;
  const canvas = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  const image = canvas.toDataURL('image/jpg');
  setLatestImage(image);
  

  let messages = [];
  if (language === 'en') {
    messages = [{role: "system", content: `Describe the person in the image. Do not describe anything about their surroundings. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response"`},
      {role: "user", content: [{type: "image_url", image_url: {url: image}}]}];
  } else if (language === 'nl') {
    messages = [{role: "system", content: `Beschrijf de persoon in de afbeelding. Beschrijf niks anders buiten de persoon. Beschrijf de omgeving niet. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response". Write in Dutch, maak de beschrijving in het Nederlands`},
      {role: "user", content: [{type: "image_url", image_url: {url: image}}]}];
  }

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHResponse`, {messages: messages});
  console.log("Description response: ", response);
  await complimentUser(response.data.response);
}
  
  
// Make a response based on the image of the user and the transcription of the user
async function complimentUser(description) {
  console.log("Getting response to answer the user");
  try {
    let messages = [];

    if (language === 'en') {
      messages = [{role: "system", content:"Your task is to tell unique compliments to the user based on the description that is given."},
      {role: "user", content: `The user is called ${userName}, and they describe themselves with the following: ${userDescription}. A picture of them is described as follows: ${description}.`}];
    } else if (language === 'nl') {
      messages = [{role: "system", content:"Jouw taak is om unieke complimenten te geven aan de gebruiker op basis van een gegeven beschrijving. Write in Dutch"},
        {role: "user", content: `De gebruiker heer, ${userName}, en ze beschrijven zichzelf als volgt: ${userDescription}. Een foto van de gebruiker is beschreven as volgt: ${description}.`}];
    }
    
  
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHResponseGroq`, {messages: messages});
    console.log("Compliment response: ", response);

    
    
    // <---- ELEVENLABS SPEAKING WIP ----->

    // console.log("Getting speech response");
    // const speech = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHSpeech`, {text: response.data.response}, {responseType: 'blob'});
    // const speech = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHSpeech`, {text: speechtext}, {responseType: 'blob'});
    // console.log("Speech response: ", speech);
    // // speech.data is already a Blob, so no need to wrap it in another Blob
    // const audioBlob = speech.data;
    // // Convert Blob to ArrayBuffer
    // const arrayBuffer = await audioBlob.arrayBuffer();
    // // Create an AudioContext instance and decode the ArrayBuffer into an AudioBuffer
    // const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    // const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    //   // Create the audio object expected by playAudio
    //   const audioObj = {
    //     audio: audioBuffer,
    //     words: speechTextArray
    //   };
    // console.log("Playing audio");
    // head.speakAudio(audioObj);

    if (response.data.response) {
      // Speak the text
      head.speakText(response.data.response);
    }

    isAvatarThinking=false;
    isWaitingForDescriptionResult=false;
    isRoasting();
  } catch (error) {
    console.error(error);
  }
}

function pauseButton(){
  if (isAvatarThinking){
      head.start();
      setPauseButtonText('Pause');
  } else {
      head.stop();
      setPauseButtonText('Resume');
  }
  isAvatarThinking = !isAvatarThinking;
}

  // Close the introduction modal
  function closeModal(){
    setConsentVideoMicrophone(true);
  }


  async function takePicture(){
    if (head.isSpeaking) {
      return;
    }


    if (language === 'en') {
      head.speakText("Let me get a good look.");
    } else if (language === 'nl') {
      head.speakText("Laten we eens goed kijken.");
    }

    setShowCountdown(true);
    setTimeout(() => {
      setCountdown(2);
    }, 1000);
    setTimeout(() => {
      setCountdown(1);
    }, 2000);
    setTimeout(() => {
      setShowCountdown(false);
      setCountdown(3);
    }, 3000);
    setTimeout(() => {
      setShowImage(true);
      DescribeImage();
    }, 3000);
  }

  function isRoasting(){
    if (!head.isSpeaking && pauseButtonText === 'Pause') {
      if (language === 'en') {
        head.speakText(`I think that was important for you to know.`);
      } else if (language === 'nl') {
        head.speakText(`Ik denk dat het belangrijk is dat je dat weet.`);
      }
      setTimeout(() => {
        setShowImage(false);
      }, 3000);
    } else {
      setTimeout(() => {
        isRoasting();
      }, 1500);
      
    }
  }

  function selectLanguage(lang) {
    setLanguage(lang);
  }


  const handleInputChange = (event) => {
    setUserDescription(event.target.value);
  };

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };

  // Check whether the user is on a mobile device
  function isMobile(){
    // if (/Mobi|Android/i.test(navigator.userAgent) || window.innerWidth < 786)
    if (window.innerWidth <= 1024){
      setIsMobileDevice(true);
    };
  }


  return (
    <div>
      <div className="App">
      <div className="BrightenUP-avatar-background">
        {showImage && !isMobileDevice? (<img src={latestImage} className="BrightenUP-picture"/>) : null}
        {showImage && isMobileDevice? (<img src={latestImage} className="BrightenUP-picture-mobile"/>) : null}
        {showCountdown && isMobileDevice ? <p className="BrightenUP-countdown-mobile">{countdown}</p>: null}
        {showCountdown && !isMobileDevice ? <p className="BrightenUP-countdown">{countdown}</p>: null}

        {isMobileDevice? <div className='avatar BrightenUP-mobile' id="avatar"></div> : <div className='avatar BrightenUP' id="avatar"></div>}
        {isMobileDevice? <video className="BrightenUP-videoCam-mobile" ref={videoRef} playsInline autoPlay muted /> : <video className="BrightenUP-videoCam" ref={videoRef} autoPlay muted />}
        
        <div id="loading"></div>
      </div>
      {!showPopup && !showImage ? <div className='BrightenUP-button-container'>
        <button className="BrightenUP-picture-button BrightenUP-button" onClick={takePicture}>Take picture</button>
        </div>: null}
      {showPopup && !isMobileDevice &&
        <div className="BrightenUP-intro-modal">
          <div className="BrightenUP-intro-modal__text">
            <div>
              <h3>It's time to brighten <span style={{color: '#F36F43'}}>UP</span></h3> 
              <p className="BrightenUP-main-text">Pick a language:</p>
              {language === 'en' ? <button className="BrightenUP-selected-button">English</button>: <button className="BrightenUP-button" onClick={() => selectLanguage("en")}>English</button>}
              {language === 'nl' ? <button className="BrightenUP-selected-button BrightenUP-nederlands">Nederlands</button>: <button className="BrightenUP-button BrightenUP-nederlands" onClick={() => selectLanguage("nl")}>Nederlands</button>}
              <p className="BrightenUP-main-text">What is your name?</p>
              <input type="text" value={userName} onChange={handleNameChange} className="BrightenUP-input-field" placeholder="Enter your name..."/>
              <p className="BrightenUP-main-text">Tell me something about yourself:</p>
              <input type="text" value={userDescription} onChange={handleInputChange} className="BrightenUP-input-field" placeholder="Enter your description..."/>
              {/* <p className="final-step">Ask <span style={{color: '#F36F43'}}>Max</span> who he is and what his role is at United Playgrounds</p> */}
            </div>
            <button className="BrightenUP-button" onClick={closeModal}>Start</button>
          </div>
          <div className="BrightenUP-intro-modal__image">
            <img src="images/mach8-tile.png"/>
          </div>
        </div>
      }
      {showPopup && isMobileDevice &&
        <div className="BrightenUP-intro-modal">
          <div className="BrightenUP-intro-modal__text-mobile">
            <div>
              <h3>It's time to brighten <span style={{color: '#F36F43'}}>UP</span></h3> 
              <p className="BrightenUP-main-text">Pick a language:</p>
              {language === 'en' ? <button className="BrightenUP-selected-button">English</button>: <button className="BrightenUP-button" onClick={() => selectLanguage("en")}>English</button>}
              {language === 'nl' ? <button className="BrightenUP-selected-button BrightenUP-nederlands-mobile">Nederlands</button>: <button className="BrightenUP-button BrightenUP-nederlands-mobile" onClick={() => selectLanguage("nl")}>Nederlands</button>}
              <p className="BrightenUP-main-text">What is your name?</p>
              <input type="text" value={userName} onChange={handleNameChange} className="BrightenUP-input-field" placeholder="Enter your name..."/>
              <p className="BrightenUP-main-text">Tell me something about yourself:</p>
              <input type="text" value={userDescription} onChange={handleInputChange} className="BrightenUP-input-field-mobile" placeholder="Enter context..."/>
              {/* <p className="final-step">Ask <span style={{color: '#F36F43'}}>Max</span> who he is and what his role is at United Playgrounds</p> */}
            </div>
            <button className="BrightenUP-button" style={{"marginTop":"25px"}} onClick={closeModal}>Start</button>
          </div>
        </div>
      }
      </div>
    </div>
  );
}

export default App;
