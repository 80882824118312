import React, { useState, useEffect, useRef } from "react";
import ReactConfetti from "react-confetti";
import "../Quizmaster.css";
import backgroundVideo from '../../assets/images/quizMaster-results-background.webm';
import goodCelebrationSound from '../../assets/sound-effects/quizMaster-results-good.mp3';
import badCelebrationSound from '../../assets/sound-effects/quizMaster-results-bad.mp3';

const QuizMasterMobileResults = ({
  totalCorrectAnswers,
  questionAmount,
  language,
}) => {
  const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const audioRef = useRef(null);
  const isGoodResult = totalCorrectAnswers > questionAmount / 2;

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [totalCorrectAnswers, questionAmount, language]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch(error => console.log("Audio playback failed:", error));
    }
  }, [audioRef.current]);

  const getResultMessage = () => {
    if (language === "en") {
      return isGoodResult ? "Good job!" : "Better luck next time!";
    } else {
      return isGoodResult ? "Goed gespeeld!" : "Volgende keer beter!";
    }
  };

  return (
    <div className="quiz-mobile-results">
      <audio ref={audioRef} src={isGoodResult ? goodCelebrationSound : badCelebrationSound} />
      <video autoPlay loop muted playsInline className="background-video">
        <source src={backgroundVideo} type="video/webm" />
      </video>
      {isGoodResult && (
        <ReactConfetti
          width={windowDimensions.width}
          height={windowDimensions.height}
          recycle={false}
          numberOfPieces={400}
          gravity={0.5}
        />
      )}
      <div className="quiz-mobile-results-content">
        <div className="quiz-mobile-results-header">
          <h1>{getResultMessage()}</h1>
        </div>
        <div className="quiz-mobile-results-bottom">
          <h3 className="results-header">{language === "en" ? "Your Results" : "Jouw resultaat"}</h3>
          <p className="quiz-mobile-results-score">
            {totalCorrectAnswers}
            {language === "en" ? " out of " : " van de "}
            {questionAmount} correct!
          </p>
          <button className="quiz-mobile-results-button" onClick={() => window.location.reload()}>
            {language === "en" ? "Start a new quiz" : "Start een nieuwe quiz"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizMasterMobileResults;
