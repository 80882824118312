import React, { useEffect, useState } from "react";

const QuizMasterMobileQuestions = ({
  headRef,
  firstQuestion,
  setFirstQuestion,
  language,
  avatarRef,
  loadingRef,
  loaded,
  loadAvatar,
  handleStartQuiz,
  questionCounter,
  AnswerClick,
  selectedAnswer,
  correctAnswerIndex,
  isAnswerCorrect,
  currentQuestion,
  questionAmount,
  loadingQuiz
}) => {
  const [explanation, setExplanation] = useState("");

  function handleExplanation(language) {
    switch (language) {
      case "nl":
        setExplanation(
          "Ik ga je een vraag stellen en jij moet kiezen uit vier antwoorden, waarvan jij denkt dat het juiste antwoord is. Snap je wat ik bedoel?"
        );
        break;
      case "en":
        setExplanation(
          "I will ask you a question and you have to choose from four answers, which you think is the correct answer. Do you understand?"
        );
        break;
    }
  }

  useEffect(() => {
    if (firstQuestion) {
      handleExplanation(language);
    }
  }, []);

  useEffect(() => {
    if (avatarRef.current) {
      loadAvatar();
    }
  }, [avatarRef.current]);

  // useEffect(() => {
  //   if (headRef.current) {
  //     headRef.current.speakText(explanation);
  //   }
  // }, [explanation, headRef.current]);

  function handleAnswerYes() {
    console.log("Ja!");
    setFirstQuestion(false);
    handleStartQuiz();
    // TODO: Go to quiz
  }
  function handleAnswerNo() {
    console.log("Nee!");
    headRef.current.speakText("Nee? Oke, laten we het dan opnieuw proberen!");
    handleExplanation(language);
  }
  function handleAnswerMaybe() {
    console.log("Misschien!");
    headRef.current.speakText(
      "Misschien? Oke, laten we het dan proberen, en kijken we weer of je het snapt!"
    );
    handleStartQuiz();
    // TODO: Go to quiz
  }
  function handleAnswerExplainAgain() {
    console.log("Leg opnieuw uit");
    headRef.current.speakText("Ok! Ik leg het opnieuw uit!");
    handleExplanation(language);
  }

  return (
    <>
      <div className="quiz-mobile-question-top-section">
        <div className="quiz-mobile-question-avatar-container">
          <div
            className="avatar QuizMaster-question-avatar"
            ref={avatarRef}
          ></div>
        </div>
        <div id="loading" ref={loadingRef}></div>
      </div>
      {/* Bottom section */}
      <div className="quiz-mobile-question-bottom-section">
        {/* Bottom section content */}
        {firstQuestion ? (
          <>
            <div className="quiz-mobile-question-info">
              <h3>Uitleg</h3>
            </div>
            <div className="quiz-mobile-question-question-container">
              <h3>{explanation}</h3>
            </div>
            <div className="quiz-mobile-question-answers-container">
              <button onClick={handleAnswerYes}>
                {language === "nl" ? "Ja!" : "Yes!"}
              </button>
              <button onClick={handleAnswerNo}>
                {language === "nl" ? "Nee!" : "No!"}
              </button>
              <button onClick={handleAnswerMaybe}>
                {language === "nl" ? "Misschien?" : "Maybe?"}
              </button>
              <button onClick={handleAnswerExplainAgain}>
                {language === "nl" ? "Leg opnieuw uit" : "Explain again"}
              </button>
            </div>
          </>
        ) : (
          <>
            {loadingQuiz ? (
              <div className="quiz-mobile-question-info">
                <h3>Loading...</h3>
              </div>
            ) : (
              <>
                <div className="quiz-mobile-question-info">
                  <h3>
                    {questionCounter + 1}/{questionAmount}
                  </h3>
                </div>
                <div className="quiz-mobile-question-question-container">
                  <h3>{currentQuestion.question}</h3>
                </div>
                <div className="quiz-mobile-question-answers-container">
                  {currentQuestion.answerOptions.map((option, index) => (
                    <button
                      key={index}
                      onClick={() => AnswerClick(option, index)}
                      className={
                        selectedAnswer !== null
                          ? index === correctAnswerIndex
                            ? "correct"
                            : index === selectedAnswer
                            ? isAnswerCorrect
                              ? "correct"
                              : "incorrect"
                            : ""
                          : ""
                      }
                      disabled={selectedAnswer !== null}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default QuizMasterMobileQuestions;
