import axios from "axios";

export const getRoastQuotesAPI = async (roastContent) => {
  console.log("Roast content: ", roastContent);
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/getRoastQuotes`, 
    { roastContent }
  );
  console.log("Roast quotes: ", response);
  return response.data;
};