import axios from "axios";

export const getToastQuotesAPI = async (toastContent) => {
  console.log("Toast content: ", toastContent);
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/getToastQuotes`, 
    { toastContent }
  );
  console.log("Toast quotes: ", response);
  return response.data;
};