// const greetingTextMobile = `Hi there! I'm Max, and I'm here to guide you through this experience. Before we dive in, let me walk you through a few quick setup steps to ensure we have a smooth conversation.
// First, please make sure your sound is turned on so you can hear me clearly.
// You'll receive a prompt to share your microphone. Please accept that, so I can hear you when you speak.
// Keep an eye on the top of your screen—whenever you see an orange prompt appear, that means I'm listening and you can go ahead and talk to me.
// If at any point you need to pause our conversation, just click the pause button. I'll freeze in place, and you can take your time. When you're ready to continue, simply press the resume button, and we'll pick up right where we left off.
// Besides that. I would like to introduce you to Mack Eight, a pioneering agency under United Playgrounds, focused on leveraging AI and emerging technologies to drive digital innovation. Would you like to know more about Mack Eight?`;

const greetingTextMobile = `Hi there! I'm Max, and I'd like to introduce you to Mack Eight, a pioneering agency under United Playgrounds, focused on leveraging AI and emerging technologies to drive digital innovation. Would you like to know more about Mack Eight?`;


export default greetingTextMobile