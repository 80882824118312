import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Mach8/App';
import Inspire from './Inspire/Inspire';
import Quizmaster from './Quizmaster/Quizmaster';
import MaxHeat from './MaxHeat/MaxHeat';
import MinHeat from './MaxHeat/MinHeat';
import BrightenUP from './BrightenUP/BrightenUP';
import ToastMaster from './ToastMaster/ToastMaster';
import KinderStories from './KinderStories/KinderStories';
import Wethouder from './Wethouder/Wethouder';
import OuderenMaatje from './OuderenMaatje/OuderenMaatje';
import ConfidenceBooster from './ConfidenceBooster/ConfidenceBooster';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-NK6QQ75T', // Replace with your GTM ID
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/ebsmds98" element={<App />} />
        <Route path="/inspire" element={<Inspire />} />
        <Route path="/quizmaster" element={<Quizmaster />} />
        <Route path="/maxheat" element={<MaxHeat />} />
        <Route path="/minheat" element={<MinHeat />} />
        <Route path="/brightenup" element={<BrightenUP />} />
        <Route path="/toastmaster" element={<ToastMaster />} />
        <Route path="/kinder-stories" element={<KinderStories />} />
        <Route path="/ouderen-maatje" element={<OuderenMaatje />} />
        <Route path="/wethouder" element={<Wethouder />} />
        <Route path="/confidence-booster" element={<ConfidenceBooster />} />
      </Routes>
    </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
