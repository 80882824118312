import React from "react";
import ReactFlagsSelect from "react-flags-select";
import Max from '../../assets/images/quizMaster-Max.svg';
export const QuizMobileIntroModal = ({
  language,
  handleLanguageSelect,
  quizOnderwerp,
  handleInputChange,
  handleCloseModal,
}) => {
  return (
    <div className="Page-QuizMaster">
      {/* Avatar Section */}
      <div
        className={`quiz-mobile-top-section`}
      >
          <div className="quiz-mobile-page-title">
            <h1 className="text-6xl">
              Quizmaster <br />
              <span className="text-[#3C0E1E]">Max</span>
            </h1>
          </div>
        <div
          className= "quiz-mobile-avatar-container"
        >
          <div
            className="avatar QuizMaster"
          >
            <img src={Max} alt="Max" />
          </div>
        </div>
      </div>
      <div className="quiz-mobile-input-container">
        <h2 className="quiz-mobile-page-description">
          Genereer een quiz over elk onderwerp en laat Quizmaster Max jou
          testen!
        </h2>
        <div className="quiz-mobile-input-wrapper">
          <input
            type="text"
            value={quizOnderwerp}
            onChange={handleInputChange}
            className="quiz-mobile-input-field"
            placeholder="Jouw quiz onderwerp..."
          />
          <ReactFlagsSelect
            selected={language === "en" ? "US" : "NL"}
            onSelect={handleLanguageSelect}
            countries={["US", "NL"]}
            customLabels={{ US: "English", NL: "Nederlands" }}
            className="quiz-mobile-language-dropdown"
            selectedSize={24}
            optionsSize={24}
            showSelectedLabel={true}
            showOptionLabel={true}
            fullWidth={true}
          />
          <button
            className="quiz-mobile-start-button"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Start de quiz!
          </button>
        </div>
      </div>
    </div>
  );
};
