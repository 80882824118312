import './MaxHeat.css';
import React, { useEffect, useState, useRef } from 'react';
import { TalkingHead } from "../utils/Talkinghead";
import axios from 'axios';


let head = null;
let loaded=false;
let isWaitingForDescriptionResult=false;
let isAvatarThinking=false; // Between ending the recording and the text being spoken

function App() {
  const videoRef = useRef();
  const [pauseButtonText, setPauseButtonText] = useState('Pause');
  const [consentVideoMicrophone, setConsentVideoMicrophone] = useState(false);
  const [lockStartButton, setLockStartButton] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [countdown, setCountdown] = useState(3);
  const [showCountdown, setShowCountdown] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [latestImage, setLatestImage] = useState(null);
  const [language, setLanguage] = useState('en');
  const [userDescription, setUserDescription] = useState("");
  const [isComplimenting, setIsComplimenting] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);



  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        setLockStartButton(false);
      }, 3000);
    }
    if (consentVideoMicrophone) {
      startVideo();    
      loadAvatar();
      setShowPopup(false);
    }
    isMobile();
    setConsentVideoMicrophone(false);
  }, [consentVideoMicrophone]);


  // Starts the videofeed.
  const startVideo = () => {
    navigator.mediaDevices.getUserMedia({ video: {} })
      .then(stream => {
        videoRef.current.srcObject = stream;
        videoRef.muted = true;
      })
      .catch(err => console.error('Error accessing webcam: ', err));
  };

  // Loads the avatar on the screen.
  const loadAvatar=async function loadAvatar() {
      loaded=true;
      // Instantiate the class
      // NOTE: Never put your API key in a client-side code unless you know
      //       that you are the only one to have access to that code!
      const nodeAvatar = document.getElementById('avatar');
      head = new TalkingHead(nodeAvatar, {
        ttsEndpoint: "https://eu-texttospeech.googleapis.com/v1beta1/text:synthesize",
        ttsApikey: process.env.REACT_APP_GOOGLE_TTS_API_KEY, 
        lipsyncModules: ["en"],
        cameraView: "upper"
      });

      // Load and show the avatar
      const nodeLoading = document.getElementById('loading');
      try {
        nodeLoading.textContent = "Loading...";
        let config = {}
        if (language === 'en') {
          config = {
            url: 'https://models.readyplayer.me/66e80709256d689574fde12a.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
            body: 'M',
            ttsVoice: "en-GB-Standard-B",
            avatarMood: 'neutral',
            ttsLang: "en-GB",
            lipsyncLang: 'en'
          }
        } else if (language === 'nl') {
          config = {
            url: 'https://models.readyplayer.me/66e80709256d689574fde12a.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
            body: 'M',
            ttsVoice: "nl-NL-Standard-C",
            avatarMood: 'neutral',
            ttsLang: "nl-NL",
            lipsyncLang: 'nl'
          }
        }
        await head.showAvatar(config, (ev) => {
          if (ev.lengthComputable) {
            let val = Math.min(1000, Math.round(ev.loaded / ev.total * 100));
            nodeLoading.textContent = "Loading " + val + "%";
          }
   
        });
        nodeLoading.style.display = 'none';
      } catch (error) {
        nodeLoading.textContent = error.toString();
      }     
  }

// Describes the image of the user everytime the recording is started. This way a description is ready for the conversation.
const DescribeImage = async () => {
  if (isWaitingForDescriptionResult) {
    return;
  }

  isWaitingForDescriptionResult=true;
  console.log("Describing the image");
  const video=videoRef.current;
  const canvas = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  const image = canvas.toDataURL('image/jpg');
  setLatestImage(image);
  

  let messages = [];
  if (language === 'en') {
    messages = [{role: "system", content: `Describe the person in the image. Do not describe anything about their surroundings. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response"`},
      {role: "user", content: [{type: "image_url", image_url: {url: image}}]}];
  } else if (language === 'nl') {
    messages = [{role: "system", content: `Beschrijf de persoon in de afbeelding. Beschrijf niks anders buiten de persoon. Beschrijf de omgeving niet. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response". Write in Dutch, maak de beschrijving in het Nederlands`},
      {role: "user", content: [{type: "image_url", image_url: {url: image}}]}];
  }

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHResponse`, {messages: messages});
  console.log("Description response: ", response);
  await roastUser(response.data.response);
}
  
  
// Make a response based on the image of the user and the transcription of the user
async function roastUser(description) {
  console.log("Getting response to answer the user");
  try {
    let messages = [];
    if (!isComplimenting){
      if (language === 'en') {
       messages = [{role: "system", content:"Your task is to roast the user based on the description that is given. Keep it light-hearted and fun."},
       {role: "user", content: `The user is: ${userDescription}. And is described as follows: ${description}.`}];
     } else if (language === 'nl') {
      messages = [{role: "system", content:"Jouw taak is om op humoristische wijze de gebruiker te beledigen op basis van de beschrijving die is gegeven. Zorg dat het niet aanstootgevend is. Schrijf in het Nederlands, write in Dutch"},
        {role: "user", content: `De gebruiker is: ${userDescription}. En wordt als volgt beschreven: ${description}.`}];
     }
   } else {
    if (language === 'en') {
      messages = [{role: "system", content:"Your task is to compliment the user based on the description that is given."},
      {role: "user", content: `The user is: ${userDescription}. And is described as follows: ${description}.`}];
    } else if (language === 'nl') {
      messages = [{role: "system", content:"Jouw taak is om de gebruiker een compliment te geven op basis van een gegeven beschrijving. Schrijf in het Nederlands, write in Dutch"},
        {role: "user", content: `De gebruiker is: ${userDescription}. En wordt als volgt beschreven: ${description}.`}];
    }
   }
  
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/VHResponseGroq`, {messages: messages});
    console.log("Roast response: ", response);

    if (response.data.response) {
      // Speak the text
      head.speakText(response.data.response);
    }

    isAvatarThinking=false;
    isWaitingForDescriptionResult=false;
    isRoasting();
  } catch (error) {
    console.error(error);
  }
}

function pauseButton(){
  if (isAvatarThinking){
      head.start();
      setPauseButtonText('Pause');
  } else {
      head.stop();
      setPauseButtonText('Resume');
  }
  isAvatarThinking = !isAvatarThinking;
}

  // Close the introduction modal
  function closeModal(){
    setConsentVideoMicrophone(true);
  }


  async function takePicture(){
    if (language === 'en') {
      head.speakText("Let me get a good look.");
    } else if (language === 'nl') {
      head.speakText("Laten we eens goed kijken.");
    }

    setShowCountdown(true);
    setTimeout(() => {
      setCountdown(2);
    }, 1000);
    setTimeout(() => {
      setCountdown(1);
    }, 2000);
    setTimeout(() => {
      setShowCountdown(false);
      setCountdown(3);
    }, 3000);
    setTimeout(() => {
      setShowImage(true);
      DescribeImage();
    }, 3000);
  }

  function isRoasting(){
    if (!head.isSpeaking && pauseButtonText === 'Pause') {
      if (language === 'en') {
        head.speakText(`I think that's enough for now.`);
      } else if (language === 'nl') {
        head.speakText(`Ik denk dat het genoeg is voor nu.`);
      }
      setTimeout(() => {
        setShowImage(false);
      }, 3000);
    } else {
      setTimeout(() => {
        isRoasting();
      }, 1500);
      
    }
  }

  function selectLanguage(lang) {
    setLanguage(lang);
  }

  // false -> mean, true -> nice
  function selectAttitude(attitude) {
    setIsComplimenting(attitude);
  }

  const handleInputChange = (event) => {
    setUserDescription(event.target.value);
  };

  // Check whether the user is on a mobile device
  function isMobile(){
    // if (/Mobi|Android/i.test(navigator.userAgent) || window.innerWidth < 786)
    if (window.innerWidth <= 1024){
      setIsMobileDevice(true);
    };
  }

  return (
    <div>
      <div className="App">
      <div className="MaxHeat-avatar-background">
        {showImage && !isMobileDevice? (<img src={latestImage} className="MaxHeat-picture"/>) : null}
        {showImage && isMobileDevice? (<img src={latestImage} className="MaxHeat-picture-mobile"/>) : null}
        {showCountdown && isMobileDevice ? <p className="MaxHeat-countdown-mobile">{countdown}</p>: null}
        {showCountdown && !isMobileDevice ? <p className="MaxHeat-countdown">{countdown}</p>: null}

        {isMobileDevice? <div className='avatar MaxHeat-mobile' id="avatar"></div> : <div className='avatar MaxHeat' id="avatar"></div>}
        {isMobileDevice? <video className="MaxHeat-videoCam-mobile" ref={videoRef} playsInline autoPlay muted /> : <video className="MaxHeat-videoCam" ref={videoRef} autoPlay muted />}
        <div id="loading"></div>
      </div>
      {!showPopup && !showImage ? <div className='MaxHeat-button-container'>
        <button className="MaxHeat-picture-button MaxHeat-button" onClick={takePicture}>Take picture</button>
        {/* <button className="MaxHeat-pause-button MaxHeat-button" onClick={pauseButton}>{pauseButtonText}</button> */}
      </div>: null}

      {showPopup && !isMobileDevice &&
        <div className="MaxHeat-intro-modal">
          <div className="MaxHeat-intro-modal__text">
            <div>
              <h3>It's time for Max <span style={{color: '#F36F43'}}>Heat</span></h3> 
              <p className="MaxHeat-main-text">Pick a language:</p>
              {language === 'en' ? <button className="MaxHeat-selected-button">English</button>: <button className="MaxHeat-button" onClick={() => selectLanguage("en")}>English</button>}
              {language === 'nl' ? <button className="MaxHeat-selected-button MaxHeat-nederlands">Nederlands</button>: <button className="MaxHeat-button MaxHeat-nederlands" onClick={() => selectLanguage("nl")}>Nederlands</button>}
              <p className="MaxHeat-main-text">Pick Max's attitude:</p>
              {!isComplimenting ? <button className="MaxHeat-selected-button">Roasting</button>: <button className="MaxHeat-button" onClick={() => selectAttitude(false)}>Roasting</button>}
              {isComplimenting ? <button className="MaxHeat-selected-button MaxHeat-complimenting">Complimenting</button>: <button className="MaxHeat-button MaxHeat-complimenting" onClick={() => selectAttitude(true)}>Complimenting</button>}
              <p className="MaxHeat-main-text">Add additional context:</p>
              <input type="text" value={userDescription} onChange={handleInputChange} className="MaxHeat-input-field" placeholder="Enter your description..."/>
            </div>
            <button className="MaxHeat-button" onClick={closeModal}>Start</button>
          </div>
          <div className="MaxHeat-intro-modal__image">
            <img src="images/mach8-tile.png"/>
          </div>
        </div>
      }
      {showPopup && isMobileDevice &&
        <div className="MaxHeat-intro-modal">
          <div className="MaxHeat-intro-modal__text-mobile">
            <div>
            <h3>It's time for Max <span style={{color: '#F36F43'}}>Heat</span></h3> 
            <p className="MaxHeat-main-text">Pick a language:</p>
              {language === 'en' ? <button className="MaxHeat-selected-button">English</button>: <button className="MaxHeat-button" onClick={() => selectLanguage("en")}>English</button>}
              {language === 'nl' ? <button className="MaxHeat-selected-button MaxHeat-nederlands-mobile">Nederlands</button>: <button className="MaxHeat-button MaxHeat-nederlands-mobile" onClick={() => selectLanguage("nl")}>Nederlands</button>}
              <p className="MaxHeat-main-text">Pick Max's attitude:</p>
              {!isComplimenting ? <button className="MaxHeat-selected-button">Roasting</button>: <button className="MaxHeat-button" onClick={() => selectAttitude(false)}>Roasting</button>}
              {isComplimenting ? <button className="MaxHeat-selected-button MaxHeat-complimenting MaxHeat-nederlands-mobile">Complimenting</button>: <button className="MaxHeat-button MaxHeat-complimenting-mobile" onClick={() => selectAttitude(true)}>Complimenting</button>}

              <p className="MaxHeat-main-text">Add additional context:</p>
              <input type="text" value={userDescription} onChange={handleInputChange} className="MaxHeat-input-field-mobile" placeholder="Enter context..."/>
              {/* <p className="final-step">Ask <span style={{color: '#F36F43'}}>Max</span> who he is and what his role is at United Playgrounds</p> */}
            </div>
            <button className="MaxHeat-button" style={{"marginTop":"25px"}} onClick={closeModal}>Start</button>
          </div>
        </div>
      }
      </div>
    </div>
  );
}

export default App;
