import "../OuderenMaatje.css";
import "./OuderenMaatjeMobile.css";
import mach8Logo from "../../assets/images/OuderenMaatje/ouderenMaatje-mach8-logo.svg";
import React, { useEffect } from "react";
import ThoughtBubble from "../Components/ThoughtBubble";

export default function OuderenMaatjeMobile({
  pauseButton,
  pauseButtonText,
  showPopup,
  closeModal,
  recordingIndicator,
  loadAvatar,
  language,
  avatarRef,
  loadingRef,
  lockStartButton,
}) {
  useEffect(() => {
    if (avatarRef && avatarRef.current) {
      loadAvatar();
    }
  }, [avatarRef, avatarRef]);

  return (
    <div className="OuderenMaatje-Mobile">
      <div className="ouderen-maatje-mach8-logo">
        <div className="mach8-logo-vector">
          <img src={mach8Logo} alt="Mach8 Logo" />
        </div>
      </div>
      {recordingIndicator ? (
        <div className="ouderen-maatje-recording-indicator">
          <ThoughtBubble text="Maxine is aan het luisteren" />
        </div>
      ) : null}
      {!showPopup && (
        <button className="ouderen-maatje-pause-button" onClick={pauseButton}>
          {pauseButtonText}
        </button>
      )}
      
      <div className="ouderen-maatje-intro-modal">
        {showPopup && (
          <div className="ouderen-maatje-intro-modal-top">
            <div className="ouderen-maatje-intro-modal-header">
              <h3 className="ouderen-maatje-intro-modal-top-text">
                <p>Hallo, ik ben <span className="ouderen-maatje-intro-modal-top-name">Maxine</span>.</p>
                <p className="mt-6">Zin om te kletsen?</p>
              </h3>
            </div>
          </div>
        )}
        <div className="ouderen-maatje-intro-modal-bottom">
          <div className="ouderen-maatje-avatar-container">
            <div className="avatar ouderen-maatje-avatar" ref={avatarRef}></div>
          </div>
        </div>
        <div ref={loadingRef} id="loading" className="text-white absolute top-5 left-5"></div>
        {showPopup && (
          <div className="ouderen-maatje-intro-modal-bottom-button-container">
            <button className="ouderen-maatje-intro-modal-bottom-button" onClick={closeModal}>
              Ja graag!
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

