import "../KinderStories.css";
import "./KinderStoriesMobile.css";
import mach8Logo from "../../assets/images/mach8-logo.svg";
import React, { useEffect } from "react";

export default function KinderStoriesMobile({
  pauseButton,
  pauseButtonText,
  showPopup,
  closeModal,
  recordingIndicator,
  loadAvatar,
  language,
  avatarRef,
  loadingRef,
  lockStartButton,
}) {
    useEffect(() => {
        console.log("Avatar ref:", avatarRef);
        if (avatarRef && avatarRef.current) {
          console.log("Loading avatar in component");
          loadAvatar();
        }
      }, [avatarRef, avatarRef]);
  return (
    <div className="kinder-stories-mobile">
      <div className="kinder-stories-mobile-mach8-logo">
        <div className="mach8-logo-vector">
          <img src={mach8Logo} alt="Mach8 Logo" />
        </div>
      </div>
      {recordingIndicator ? (
        <div className="kinder-stories-mobile-recording-indicator-mobile">
        <p className="recording-indicator-text">Ik ben aan het luisteren</p>
        <div className="dots">
          <div className="dot-1">.</div>
          <div className="dot-2">.</div>
          <div className="dot-3">.</div>
        </div>
      </div>
      ) : null}
      {!showPopup && (
        <button className="kinder-stories-mobile-pause-button" onClick={pauseButton}>
          {pauseButtonText}
        </button>
      )}
      
        <div className="kinder-stories-mobile-intro-modal">
        {showPopup && (
          <div className="kinder-stories-mobile-intro-modal-top">
            <div className="kinder-stories-mobile-intro-modal-header">
              <h3 className="kinder-stories-mobile-intro-modal-top-text">
                Fun starts with
              </h3>
              <h3 className="kinder-stories-mobile-intro-modal-top-name">
                <div className="letter kinder-stories-mobile-intro-modal-letter-M">
                  M
                </div>
                <div className="letter kinder-stories-mobile-intro-modal-letter-A">
                  A
                </div>
                <div className="letter kinder-stories-mobile-intro-modal-letter-X">
                  X
                </div>
              </h3>
            </div>
          </div>
          )}
          <div className="kinder-stories-mobile-intro-modal-bottom">
            {showPopup && <div className="kinder-stories-mobile-chatbubble-left">Samen kunnen we spelletjes doen!</div>}
            <div className="kinder-stories-mobile-avatar-container">
                <div
                  className="avatar kinder-stories-mobile-avatar"
                  ref={avatarRef}
                ></div>
            </div>
          </div>
          <div ref={loadingRef} id="loading" className="text-black"></div>
          {showPopup && (
            <div className="kinder-stories-mobile-intro-modal-bottom-button-container">
              <button className="kinder-stories-mobile-intro-modal-bottom-button" onClick={closeModal}>Begin!</button>
            </div>
          )}
        </div>
      
    </div>
  );
}
