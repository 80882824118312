import "./ConfidenceBooster.css";
import React, { useEffect, useState, useRef } from "react";
import { TalkingHead } from "../utils/Talkinghead";
import axios from "axios";
import { base64ToArrayBuffer } from "../utils/base64toArraybuffer";
import {
  ConfidenceBoosterGreetingEN,
  ConfidenceBoosterEndingEN,
  ConfidenceBoosterGreetingNL,
  ConfidenceBoosterEndingNL,
} from "./ConfidenceBoosterStandardSpeech";
import lwLogo from "../assets/images/livewall-logo.svg";
import VHIntroModal from "../Components/VHIntroModal";
import VHFinishedPage from "../Components/VHFinishedPage";
import VHPolaroid from "../Components/VHPolaroid";
import { getToastQuotesAPI } from "../api/GetToastQuotesAPI";
import { Link } from 'react-router-dom';


let head = null;
let loaded = false;
let isWaitingForDescriptionResult = false;
let isAvatarThinking = false;

function App() {
  const videoRef = useRef();
  const avatarRef = useRef(null);
  const loadingRef = useRef(null);
  const [pauseButtonText, setPauseButtonText] = useState("Pause");
  const [consentVideoMicrophone, setConsentVideoMicrophone] = useState(false);
  const [lockStartButton, setLockStartButton] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [countdown, setCountdown] = useState(3);
  const [showCountdown, setShowCountdown] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [latestImage, setLatestImage] = useState(null);
  const [language, setLanguage] = useState("nl");
  const [userDescription, setUserDescription] = useState("");
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [ConfidenceBoosterSpeech, setConfidenceBoosterSpeech] = useState({});

  // New state variables for finished page
  const [finishedSpeaking, setFinishedSpeaking] = useState(false);
  const [toastText, setToastText] = useState("");
  const [email, setEmail] = useState("");
  const [toastQuotes, setToastQuotes] = useState("");

  useEffect(() => {
    loadAvatar();
    isMobile();

    // Extract parameters from URL
    const params = new URLSearchParams(window.location.search);
    const languageParam = params.get("language");
    const contextParam = params.get("context");

    if (!userDescription && contextParam) {
      // Decode the context parameter from Base64
      const decodedDescription = base64DecodeUnicode(contextParam);
      setUserDescription(decodedDescription);
      setLanguage(languageParam || "en");
      // Hide the intro modal
      setShowPopup(false);

      // IMPORTANT: Ensure consentVideoMicrophone remains false
      setConsentVideoMicrophone(false);
    }
  }, []);


  useEffect(() => {
    if (showPopup) {
      // Adjust styles or any other necessary updates when popup is shown
    }
  }, [language, showPopup]);

  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        setLockStartButton(false);
      }, 3000);
    }
    if (consentVideoMicrophone) {
      startVideo();
      prepSpeech();
      setShowPopup(false);
    }
  }, [consentVideoMicrophone]);

  useEffect(() => {
    if (!finishedSpeaking) {
      loadAvatar();
    }
  }, [finishedSpeaking]);


  // Starts the video feed.
  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.muted = true;
      })
      .catch((err) => console.error("Error accessing webcam: ", err));
  };

  const stopVideo = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  // Loads the avatar on the screen.
  const loadAvatar = async () => {
    if (loaded) return;
    loaded = true;
    const nodeAvatar = avatarRef.current;

    // Determine lipsync module based on language
    const lipsyncModule = language === "nl" ? "nl" : "en";

    head = new TalkingHead(nodeAvatar, {
      ttsEndpoint: "https://eu-texttospeech.googleapis.com/v1beta1/text:synthesize",
      ttsApikey: process.env.REACT_APP_GOOGLE_TTS_API_KEY,
      lipsyncModules: [lipsyncModule],
      cameraView: "upper",
    });

    // Load and show the avatar
    const nodeLoading = loadingRef.current;
    try {
      nodeLoading.textContent = "Loading...";
      let config = {};

      if (language === "en") {
        config = {
          url: "https://models.readyplayer.me/6728e33613dca51a9fa0c972.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png",
          body: "F",
          ttsVoice: "en-GB-Standard-B",
          avatarMood: "neutral",
          ttsLang: "en-GB",
          lipsyncLang: "en",
        };
      } else if (language === "nl") {
        config = {
          url: "https://models.readyplayer.me/6728e33613dca51a9fa0c972.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png",
          body: "F",
          ttsVoice: "nl-NL-Standard-C",
          avatarMood: "neutral",
          ttsLang: "nl-NL",
          lipsyncLang: "nl",
        };
      }

      await head.showAvatar(config, (ev) => {
        if (ev.lengthComputable) {
          let val = Math.min(1000, Math.round((ev.loaded / ev.total) * 100));
          nodeLoading.textContent = "Loading " + val + "%";
        }
      });
      nodeLoading.style.display = "none";
    } catch (error) {
      nodeLoading.textContent = error.toString();
    }
  };


  // Describes the image of the user every time the recording is started. This way a description is ready for the conversation.
  const DescribeImage = async () => {
    if (isWaitingForDescriptionResult) {
      return;
    }

    isWaitingForDescriptionResult = true;
    console.log("Describing the image");
    const video = videoRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL("image/jpg");
    setLatestImage(image);

    let messages = [];
    if (language === "en") {
      messages = [
        {
          role: "system",
          content: `Describe the person in the image. Do not describe anything about their surroundings. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response"`,
        },
        {
          role: "user",
          content: [{ type: "image_url", image_url: { url: image } }],
        },
      ];
    } else if (language === "nl") {
      messages = [
        {
          role: "system",
          content: `Beschrijf de persoon in de afbeelding. Beschrijf niks anders buiten de persoon. Beschrijf de omgeving niet. FORMAT: Output as JSON in the following format: {response:'text'} . It is an object with a single string field called "response". Write in Dutch, maak de beschrijving in het Nederlands`,
        },
        {
          role: "user",
          content: [{ type: "image_url", image_url: { url: image } }],
        },
      ];
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/VHResponse`,
      { messages: messages }
    );
    console.log("Description response: ", response);
    await toastUser(response.data.response);
  };


  // Make a response based on the image of the user and the user’s description
  async function toastUser(description) {
    console.log("Getting response to answer the user");
    try {
      let messages = [];

      messages = [
        {
          role: "system",
          content:
            `Jouw taak is om de gebruiker enthousiast te maken en gerust te stellen over hun toekomstige baan. Beschrijf de uitstraling en je eerste indruk van de gebruiker. Gebruik de meegegeven context om het persoonlijk te maken. Schrijf in het Nederlands, write in Dutch. Schrijf niet meer dan 100 woorden.`,
        },
        {
          role: "user",
          content: `De gebruiker heeft de volgende context ingevuld: ${userDescription}. En wordt als volgt beschreven: ${description}.`,
        },
      ];
      

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/VHResponseGroq`,
        { messages: messages }
      );
      console.log("Toast response: ", response);

      // Store the toast text in state
      const currentToastText = response.data.response;
      setToastText(currentToastText);

      console.log("Getting speech response");
      // Generate audio and visemes
      const speechResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/VHSpeech`,
        {
          text: response.data.response,
          // voiceId: "bIHbv24MWmeRgasZH58o",
          voiceId:"XrExE9yKIg1WjnnlVkGX"
        }
      );

      // Convert base64 to ArrayBuffer
      const arrayBuffer = base64ToArrayBuffer(
        speechResponse.data.response.audio
      );

      // Create an AudioContext instance and decode the ArrayBuffer into an AudioBuffer
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      // Create the audio object expected by playAudio
      const audioObj = {
        audio: audioBuffer,
        words: speechResponse.data.response.alignment.words,
        wtimes: speechResponse.data.response.alignment.wtimes,
        wdurations: speechResponse.data.response.alignment.wdurations,
      };


      console.log("Playing audio");
      head.speakAudio(audioObj);

      isAvatarThinking = false;
      isWaitingForDescriptionResult = false;
      checkIfFinishedSpeaking();
      // const quotes = await getToastQuotes(currentToastText);
      // setToastQuotes(quotes);
    } catch (error) {
      console.error(error);
      isAvatarThinking = false;
      isWaitingForDescriptionResult = false;
    }
  }

  async function getToastQuotes(toastContent) {
    const response = await getToastQuotesAPI(toastContent);
    console.log("Toast quotes: ", response);
    return response.quotes;
  }

  function pauseButton() {
    if (isAvatarThinking) {
      head.start();
      setPauseButtonText("Pause");
    } else {
      head.stop();
      setPauseButtonText("Resume");
    }
    isAvatarThinking = !isAvatarThinking;
  }

  // Close the introduction modal
  function closeModal() {
    setConsentVideoMicrophone(true);
  }

  async function takePicture() {
    head.speakAudio(ConfidenceBoosterSpeech.greeting);

    setShowCountdown(true);
    setTimeout(() => {
      setCountdown(2);
    }, 1000);
    setTimeout(() => {
      setCountdown(1);
    }, 2000);
    setTimeout(() => {
      setShowCountdown(false);
      setCountdown(3);
      setShowImage(true);
      DescribeImage();
      stopVideo(); // Turn off the user's camera after taking the picture
    }, 3000);
  }

  async function checkIfFinishedSpeaking() {
    if (!head.isSpeaking && pauseButtonText === "Pause") {
      head.speakAudio(ConfidenceBoosterSpeech.ending);
      setTimeout(() => {
        // setShowImage(false);
        // // Set finishedSpeaking to true after ending speech
        // setFinishedSpeaking(false);
        // setShowPopup(true);
        window.location.assign('/confidence-booster');
      }, 6000);
    } else {
      setTimeout(() => {
        checkIfFinishedSpeaking();
      }, 1500);
    }
  }

  async function prepSpeech() {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    if (language === "en") {
      const arrayBufferGreeting = base64ToArrayBuffer(
        ConfidenceBoosterGreetingEN.audio
      );
      ConfidenceBoosterGreetingEN.audio = await audioContext.decodeAudioData(
        arrayBufferGreeting
      );
      const arrayBufferEnding = base64ToArrayBuffer(
        ConfidenceBoosterEndingEN.audio
      );
      ConfidenceBoosterEndingEN.audio = await audioContext.decodeAudioData(
        arrayBufferEnding
      );
      ConfidenceBoosterSpeech.greeting = ConfidenceBoosterGreetingEN;
      ConfidenceBoosterSpeech.ending = ConfidenceBoosterEndingEN;
    } else if (language === "nl") {
      const arrayBufferGreeting = base64ToArrayBuffer(
        ConfidenceBoosterGreetingNL.audio
      );
      ConfidenceBoosterGreetingNL.audio = await audioContext.decodeAudioData(
        arrayBufferGreeting
      );
      const arrayBufferEnding = base64ToArrayBuffer(
        ConfidenceBoosterEndingNL.audio
      );
      ConfidenceBoosterEndingNL.audio = await audioContext.decodeAudioData(
        arrayBufferEnding
      );
      ConfidenceBoosterSpeech.greeting = ConfidenceBoosterGreetingNL;
      ConfidenceBoosterSpeech.ending = ConfidenceBoosterEndingNL;
    }
  }


  const handleLanguageSelect = (countryCode) => {
    if (countryCode === "US" || countryCode === "GB") {
      setLanguage("en");
    } else if (countryCode === "NL") {
      setLanguage("nl");
    }
    // Reload speech when language changes
    // prepSpeech();
  };

  const handleInputChange = (event) => {
    setUserDescription(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMailToast = async () => {
    // Implement email functionality
    setFinishedSpeaking(false);
    startVideo();
  };

  // Check whether the user is on a mobile device
  function isMobile() {
    if (window.innerWidth <= 1024) {
      setIsMobileDevice(true);
    }
  }


  function selectLanguage(lang) {
    setLanguage(lang);
  };

  // Adjust classNames based on showPopup and isMobileDevice
  const avatarClass = `${isMobileDevice ? "ConfidenceBooster-mobile" : "ConfidenceBooster"} ${
    showPopup ? "ConfidenceBooster-popup" : "ConfidenceBooster"
  }`;

  // Added handleConsentVideoMicrophone to match MaxHeat.js
  function handleConsentVideoMicrophone(consent) {
    setConsentVideoMicrophone(consent);
    prepSpeech();
  }

  function handleModalButton() {
    setConsentVideoMicrophone(true);
    // Base64 encode the userDescription
    const encodedDescription = base64EncodeUnicode(userDescription);

    // Update the URL with parameters
    const params = new URLSearchParams();
    params.set("language", language);
    params.set("context", encodedDescription);

    const newURL =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      params.toString();
    window.history.replaceState({ path: newURL }, "", newURL);

    // Call prepSpeech after ensuring head is initialized and audio context is resumed
    prepSpeech();
  }

  // Encoding for params
  function base64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
        match,
        p1
      ) {
        return String.fromCharCode('0x' + p1);
      })
    );
  }

  function base64DecodeUnicode(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  const handleLogoClick = () => {
    window.location.assign('/confidence-booster');
  };

  return (
    <div>
      <div className={`ConfidenceBooster-App Page-ConfidenceBooster ConfidenceBooster-background`} >
      <div className="ConfidenceBooster-mach8-logo">
          <div className="ConfidenceBooster-mach8-logo-vector" onClick={handleLogoClick}>
            <img src={lwLogo} alt="Mach8 Logo" />
          </div>
      </div>
        {/* Content */}
        {!finishedSpeaking ? (
          <>
            <div className="ConfidenceBooster-avatar-background">
              {/* Polaroid Container */}
              {!showPopup && (
                <VHPolaroid
                  videoRef={videoRef}
                  takePicture={takePicture}
                  showCountdown={showCountdown}
                  countdown={countdown}
                  showImage={showImage}
                  latestImage={latestImage}
                  isMobileDevice={isMobileDevice}
                  buttonStyle={"ConfidenceBooster-picture-button ConfidenceBooster-button"}
                  pageName={"ConfidenceBooster"}
                  handleConsentVideoMicrophone={handleConsentVideoMicrophone}
                  consentVideoMicrophone={consentVideoMicrophone}
                />
              )}
            </div>

            {/* Intro Modal */}
            {showPopup && (
              <VHIntroModal
                isMobileDevice={isMobileDevice}
                language={language}
                userDescription={userDescription}
                isComplimenting={false}
                handleInputChange={handleInputChange}
                handleModalButton={handleModalButton}
                buttonStyle={"ConfidenceBooster-button ConfidenceBooster-modal-button"}
                buttonText={"Tijd voor een foto!"}
                titleText={
                  <>
                    <span style={{ color: "#FFFFFF" }}>Confidence booster</span> <br />
                    <span className="ConfidenceBooster-intro-modal__text-bold" style={{ color: "#FFFFFF" }}>Max</span>
                  </>
                }                
                infoText={`Ben je benieuwd welke eerste indruk je toont? Laten we een foto maken en kijken wat je uitstraalt!`}
                contextText={`Voeg extra context toe`}
                inputPlaceholder={"Type hier..."}
                pageName={"ConfidenceBooster"}
              />
            )}
            <div className="ConfidenceBooster-avatar-container justify-self-end">
              <div className="avatar ConfidenceBooster" ref={avatarRef}></div>
              <div ref={loadingRef} id="loading" className="text-black"></div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default App;